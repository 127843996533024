import { LayoutItem, LayoutSubItem } from '../layoutItems.types'

export const getCollectionsData = (includes_head_of_collection: boolean) => {
  const subItems: LayoutSubItem[] = [
    {
      id: 'Formation of Call-lists',
      label: 'Formation_of_Call-lists',
      link: '/call-lists'
    },
    {
      id: 'automated-calling',
      label: 'Automated-calling',
      link: '/automated-calling'
    },
    {
      id: 'external-agencies',
      label: 'External Agencies',
      link: '/external-agencies'
    },
    {
      id: 'collection-debts',
      label: 'Debts',
      icon: 'ri-aliens-line',
      link: '/debts'
    },
    {
      id: 'collection-debts-queue',
      label: 'Debts queue',
      icon: 'ri-aliens-line',
      link: '/debts-queue'
    },
    {
      id: 'collection-editing-call-results',
      label: 'Editing call results',
      icon: 'bx bx-pencil',
      link: '/editing-call-results'
    },
    {
      id: 'collection-supervisor',
      label: 'Supervisor',
      icon: 'bx bx-pencil',
      link: '/supervisor'
      /*childItems: [
            { id: 1, label: "View all", link: "/queues", parentId: "collection-queues" },
            { id: 2, label: "Create queue", link: "/queues-create", parentId: "collection-queues" },
            { id: 3, label: "View queue", link: "/queues-view", parentId: "collection-queues", hidden: true },
            { id: 4, label: "Edit queue", link: "/queues-edit", parentId: "collection-queues", hidden: true },
        ]*/
    },
    {
      id: 'reloading',
      label: 'Reloading',
      link: '/reloading'
    }
  ]

  const headOfCollection: LayoutSubItem[] = includes_head_of_collection
    ? [
        {
          id: 'collection-rec-payments',
          link: '/rec-payments',
          icon: '',
          label: 'recurring_payments'
        },
        {
          id: 'collection-segments',
          label: 'Segments',
          icon: 'bx bx-pencil',
          link: '/segments',
          childItems: [
            {
              id: 'view-all-segments',
              label: 'View all',
              link: '/segments'
            },
            // { id: 2, label: "Create segment", link: "/segments-create", parentId: "collection" },
            {
              id: 'segments-view',
              hidden: true,
              label: 'View segment',
              link: '/segments-view'
            }
            // { id: 4, label: "Edit segment", link: "/segments-edit", parentId: "collection", hidden: true },
          ]
        },
        {
          id: 'collectors',
          label: 'Collectors',
          icon: 'ri-aliens-line',
          link: '/collectors'
        },
        {
          id: 'groups',
          label: 'Groups',
          icon: 'ri-aliens-line',
          link: '/groups'
        },
        {
          id: 'collection-queues',
          label: 'Queues',
          icon: 'bx bx-pencil',
          link: '/queues',
          childItems: [
            {
              id: 'view-all-queus',
              label: 'View all',
              link: '/queues'
            },
            {
              id: 'queues-create',
              label: 'Create queue',
              link: '/queues-create'
            },
            {
              id: 'queues-view',
              hidden: true,
              label: 'View queue',
              link: '/queues-view'
            },
            {
              id: 'queues-edit',
              hidden: true,
              label: 'Edit queue',
              link: '/queues-edit'
            }
          ]
        },
        {
          id: 'collection-sell',
          label: 'Debts Sell',
          icon: 'bx bx-pencil',
          link: '/debts-sell'
        }
      ]
    : []

  subItems.push(...headOfCollection)

  const collectionsData: LayoutItem = {
    id: 'collection',
    label: 'Collections',
    icon: 'mdi mdi-account-cash-outline',
    subItems
  }

  return collectionsData
}
