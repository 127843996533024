import { api, omitBlankEntries } from './api'

type Chargeback = {
  chargeback_date: string
  created_at: string
  funds_transaction_id: string
  id: string
  updated_at: string | null
  user_id: string
  username: string
  name: string
  surname: string
  midname: string
}

type SendChargeback = {
  transaction_id: string
  chargeback_date: string
}

type Atol = {
  id: number
  created_at: string
  name: string
  is_refunded: boolean
}

type Transaction = {
  id: string
  created_at: string
  updated_at: string
  profile_id: string
  last_name: string
  first_name: string
  middle_name: string
  loan_id: string
  direction: string
  amount: number
  accept_date: string
  is_accepted: boolean
  comment: string
  is_prepayment: boolean
  payment_type: 1 | 2 | 3 | 4 | null
  receipt_date: string | null
}

type SendTrasaction = {
  loan_id: string
  amount: number
  transaction_id: string
  transaction_date: string
  comment: string
}

type PaymentScheduleItem = {
  created_at: string
  id: string
  interest_payment: number
  is_paid: boolean
  loan_id: string
  payment_amount: number
  payment_date: string
  principal_payment: number
  remaining_principal: number
  updated_at: string
}

export const {
  useGetChargebackQuery,
  useSetChargebackMutation,
  useGetAtolListQuery,
  useSetAtolDownloadMutation,
  useGetPaymentGatesQuery,
  useGetCurrentPaymentGateQuery,
  useUpdateCurrentPaymentGateMutation,
  useSetReloadContractMutation,
  useGetTransactionsQuery,
  useGetTransactionByIdQuery,
  useSetTransactionMutation,
  useGetPaymentScheduleQuery,
  useDownloadPaymentScheduleMutation,
  useGetPaymentTypesQuery
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getChargeback: builder.query<Chargeback[], string>({
      query: (loanId) => ({
        url: `crm/v1/payments/chargeback/${loanId}`
      }),
      providesTags: (_result, _err, id) => [{ type: 'Chargeback', id }]
    }),
    setChargeback: builder.mutation<void, SendChargeback>({
      query: (body) => ({
        url: `crm/v1/payments/chargeback`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['Chargeback']
    }),
    getAtolList: builder.query<Atol[], string>({
      query: (loanId) => ({
        url: `crm/v1/loans/${loanId}/atol`
      })
    }),
    setAtolDownload: builder.mutation<
      Blob,
      { loanId: string; atolId: number; refunded: boolean }
    >({
      query: ({ loanId, atolId, refunded }) => ({
        url: `crm/v1/loans/${loanId}/atol/${atolId}?is_refunded=${refunded}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
        formData: true
      })
    }),
    setReloadContract: builder.mutation<
      any,
      {
        loanId: string
        body: {
          drop_pa: boolean
          drop_insurance: boolean
          drop_external_services_ids: string[]
        }
      }
    >({
      query: ({ loanId, body }) => ({
        url: `crm/v1/payments/${loanId}/reload-contract`,
        method: 'POST',
        body: body
      })
    }),
    getPaymentGates: builder.query<string[], void>({
      query: () => ({
        url: `crm/v1/payments/payment-gateways`
      })
    }),
    getCurrentPaymentGate: builder.query<string, void>({
      query: () => ({
        url: `crm/v1/payments/payment-gateways/current`
      }),
      providesTags: ['PaymentGate']
    }),
    updateCurrentPaymentGate: builder.mutation<void, string>({
      query: (gate) => {
        return {
          url: `crm/v1/payments/payment-gateways`,
          method: 'PUT',
          params: { gateway_key: gate }
        }
      },
      invalidatesTags: ['PaymentGate']
    }),
    getTransactions: builder.query<
      Transaction[],
      (Partial<Transaction> & { offset?: number; limit?: number }) | void
    >({
      query: (params) => ({
        url: 'crm/v1/payments/funds-transactions',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Transaction', id }) as const),
        { type: 'Transaction' as const, id: 'LIST' }
      ]
    }),
    getTransactionById: builder.query<Transaction, string>({
      query: (fund_id) => ({
        url: `/crm/v1/payments/funds-transactions/${fund_id}`
      })
    }),
    setTransaction: builder.mutation<SendTrasaction, any>({
      query: (body) => ({
        url: `/crm/v1/payments/hand_payment_in`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (_result, error, { id }) =>
        !error ? [{ type: 'Transaction', id: id }] : []
    }),
    getPaymentSchedule: builder.query<PaymentScheduleItem[], string>({
      query: (loanId) => ({
        url: `/crm/v1/payments/installments/schedules/${loanId}`
      })
    }),
    getPaymentTypes: builder.query<{ id: number; name: string }[], void>({
      query: () => ({
        url: `/crm/v1/payments/payment-types`
      }),
      providesTags: () => ['PaymentTypes']
    }),
    downloadPaymentSchedule: builder.mutation<string, string>({
      query: (loanId) => ({
        url: `/crm/v1/payments/installments/schedules/${loanId}/export-csv`,
        method: 'GET',
        responseHandler: (res) => res.blob()
      }),
      transformResponse: (res: Blob) => window.URL.createObjectURL(res)
    })
  })
})
