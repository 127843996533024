const TEST_SERVER = 'https://crm.dev.cyberprod.ru'
export const SUGGESTIONS_URL = 'https://suggestions.dadata.ru'
export const SERVER = window.origin.includes('localhost')
  ? TEST_SERVER
  : process.env.REACT_APP_API_ENDPOINT || TEST_SERVER
export const LIST_LIMIT = 50
export const LIST_LIMIT_DEBTS = 500

console.log('params =>', TEST_SERVER, process.env)

export const ENTITY_TYPE = {
  PRODUCT: 'products',
  EXTENSIONS: 'extensions',
  CLIENTS: 'clients',
  PERSONS: 'persons',
  PROFILES: 'profiles',
  APPLICATIONS: 'applications',
  PROMOCODES: 'promocodes',
  SEGMENTS: 'segments',
  QUEUES: 'queue-params',
  COLLECTORS: 'collectors',
  DEBTS: 'debts',
  DEBTS_QUEUE: 'debts_queue',
  PAYMENTS: 'payments',
  LOAN_PAYMENTS: 'funds-transactions',
  LOAN_TRACNHES: 'tranches',
  LOAN_OPERATIONS: 'operations',
  HAND_PAYMENTS: 'hand_payment_in',
  LOANS: 'loans',
  HOLIDAYS: 'holidays',
  LOANS_BKI: 'loans_bki',
  COLLECTIONS_EXTERNAL: 'collections-external',
  DOCUMENTS: 'documents',
  CALLS: 'communications',
  COMMUNICATION: 'communication',
  COMMUNICATIONS: 'communications-api',
  RESULT: 'result',
  POST_RESULT: 'post_result',
  REASON: 'reason',
  DOCUMENTS_TYPES: 'documents_types',
  EXTERNAL_SERVICES_SETTINGS: 'external_services_settings',
  DIALOGUES: 'dialogues',
  NOTIFICATIONS_TEMPLATES: 'notifications_templates',
  NOTIFICATIONS_MAPPING_TEMPLATES: 'notifications_mapping_templates',
  NOTIFICATIONS_SENDERS: 'notifications_senders',
  USERS: 'users',
  USER: 'user',
  ROLES: 'roles',
  PERMISSIONS: 'permissions',
  LOGOUT: 'logout',
  VERIFICATION_APPLICATIONS: 'verification_applications',
  GROUPS: 'groups',
  REPORTS: 'reports',
  SUPERVISOR_REPORTS: 'supervisor-reports',
  SUPERVISOR: 'supervisor',
  TAGS: 'tags',
  LOANS_TAGS: 'loans_tags',
  CLIENT_TAGS: 'clients_tags',
  UPLOAD_DICT_EXTREMISTS: 'upload_dict_extremists',
  UPLOAD_DICT_INTERDEPARTMENTAL_COMMISSION:
    'upload_dict_interdepartmental_commission',
  UPLOAD_DICT_CENTRAL_BANK: 'upload_dict_central_bank',
  UPLOAD_DICT_OON: 'upload_dict_oon',
  FIND_PERSON: 'find_person',
  PERSON: 'person',
  STRATEGIES: 'strategies',
  TEMPLATES: 'templates',
  CREDIT_BUREAU: 'credit-Bureau',
  SIP: 'sip-phone',
  CHAT: 'chat-api',
  CLAIMS_TOPIC: 'claims_topic',
  CLAIMS: 'claims'
}

const END_POINTS_CREATE = {
  [ENTITY_TYPE.SEGMENTS]: `${SERVER}/crm/v1/roles/stats/segments`,
  [ENTITY_TYPE.COLLECTIONS_EXTERNAL]: `${SERVER}/crm/v1/collections-external/agencies/add`
}

const END_POINTS_UPDATE = {
  [ENTITY_TYPE.COLLECTIONS_EXTERNAL]: `${SERVER}/crm/v1/collections-external/agencies`
}

const END_POINTS_LIST = {
  [ENTITY_TYPE.COLLECTIONS_EXTERNAL]: `${SERVER}/crm/v1/collections-external/agencies`
}

export const END_POINTS = {
  [ENTITY_TYPE.SUPERVISOR_REPORTS]: `${SERVER}/crm/v1/reports/supervisor`,
  [ENTITY_TYPE.SUPERVISOR]: `${SERVER}/crm/v1/supervisor`,
  [ENTITY_TYPE.REPORTS]: `${SERVER}/crm/v1/reports`,
  [ENTITY_TYPE.PRODUCT]: `${SERVER}/crm/v1/products`,
  [ENTITY_TYPE.EXTENSIONS]: `${SERVER}/crm/v1/extension-settings`,
  [ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS]: `${SERVER}/crm/v1/external-services-settings`,
  [ENTITY_TYPE.CLIENTS]: `${SERVER}/crm/v1/clients`,
  [ENTITY_TYPE.PERSONS]: `${SERVER}/crm/v1/calling/person`,
  [ENTITY_TYPE.PROFILES]: `${SERVER}/crm/v1/profiles`,
  [ENTITY_TYPE.APPLICATIONS]: `${SERVER}/crm/v1/applications`,
  [ENTITY_TYPE.PAYMENTS]: `${SERVER}/crm/v1/payments/funds-transactions`,
  [ENTITY_TYPE.HAND_PAYMENTS]: `${SERVER}/crm/v1/payments/hand_payment_in`,
  [ENTITY_TYPE.LOANS]: `${SERVER}/crm/v1/loans`,
  [ENTITY_TYPE.HOLIDAYS]: `${SERVER}/crm/v1/loans-holidays`,
  [ENTITY_TYPE.LOANS_BKI]: `${SERVER}/crm/v1/loans-bki-events`,
  [ENTITY_TYPE.COLLECTIONS_EXTERNAL]: `${SERVER}/crm/v1/collections-external`,
  [ENTITY_TYPE.DOCUMENTS]: `${SERVER}/crm/v1/documents`,
  [ENTITY_TYPE.CALLS]: `${SERVER}/crm/v1/calling`,
  [ENTITY_TYPE.COMMUNICATION]: `crm/v1/communications/get-communication-by-id`,
  [ENTITY_TYPE.COMMUNICATIONS]: `${SERVER}/communications-api/v1/communications`,
  [ENTITY_TYPE.RESULT]: `${SERVER}/crm/v1/calling/result`,
  [ENTITY_TYPE.POST_RESULT]: `${SERVER}/crm/v1/calling/call-result`,
  [ENTITY_TYPE.REASON]: `${SERVER}/crm/v1/calling/reason`,
  [ENTITY_TYPE.DOCUMENTS_TYPES]: `${SERVER}/crm/v1/documents/document-types`,
  [ENTITY_TYPE.DIALOGUES]: `${SERVER}/crm/v1/feedbacks`,
  [ENTITY_TYPE.NOTIFICATIONS_TEMPLATES]: `${SERVER}/crm/v1/notifications_templates`,
  [ENTITY_TYPE.NOTIFICATIONS_MAPPING_TEMPLATES]: `${SERVER}/crm/v1/mapping_templates`,
  [ENTITY_TYPE.NOTIFICATIONS_SENDERS]: `${SERVER}/crm/v1/notifications_senders`,
  [ENTITY_TYPE.USERS]: `${SERVER}/crm/v1/keycloak/users`,
  [ENTITY_TYPE.USER]: `${SERVER}/crm/v1/keycloak/user`,
  [ENTITY_TYPE.ROLES]: `${SERVER}/crm/v1/keycloak/groups`,
  [ENTITY_TYPE.PERMISSIONS]: `${SERVER}/crm/v1/keycloak/existing-permissions`,
  [ENTITY_TYPE.LOGOUT]: `${SERVER}/crm/v1/logout`,
  [ENTITY_TYPE.VERIFICATION_APPLICATIONS]: `${SERVER}/crm/v1/roles/verifications/applications`,
  [ENTITY_TYPE.RELOAD_CONTRACTS]: `${SERVER}/crm/v1/payments`,
  [ENTITY_TYPE.PROMOCODES]: `${SERVER}/crm/v1/promocodes`,
  [ENTITY_TYPE.SEGMENTS]: `${SERVER}/crm/v1/calling/stages`,
  [ENTITY_TYPE.QUEUES]: `${SERVER}/crm/v1/calling/queue-params`,
  [ENTITY_TYPE.COLLECTORS]: `${SERVER}/crm/v1/calling/collectors`,
  [ENTITY_TYPE.GROUPS]: `${SERVER}/crm/v1/calling/groups`,
  [ENTITY_TYPE.DEBTS]: `${SERVER}/crm/v1/calling/loans-in-debt-by-user`,
  [ENTITY_TYPE.DEBTS_QUEUE]: `${SERVER}/crm/v1/calling/loans-queue`,
  [ENTITY_TYPE.TAGS]: `${SERVER}/crm/v1/tags`,
  [ENTITY_TYPE.LOANS_TAGS]: `${SERVER}/crm/v1/tags/loans`,
  [ENTITY_TYPE.CLIENT_TAGS]: `${SERVER}/crm/v1/tags/clients`,
  [ENTITY_TYPE.CREDIT_BUREAU]: `${SERVER}/crm/v1/credit-Bureau`,
  [ENTITY_TYPE.UPLOAD_DICT_EXTREMISTS]: `${SERVER}/handbook-extremists/v1/upload-dict-extremists`,
  [ENTITY_TYPE.UPLOAD_DICT_INTERDEPARTMENTAL_COMMISSION]: `${SERVER}/handbook-extremists/v1/upload-dict-interdepartmental-commission`,
  [ENTITY_TYPE.UPLOAD_DICT_CENTRAL_BANK]: `${SERVER}/handbook-extremists/v1/upload-dict-central-bank`,
  [ENTITY_TYPE.UPLOAD_DICT_OON]: `${SERVER}/handbook-extremists/v1/upload-dict-oon`,
  [ENTITY_TYPE.FIND_PERSON]: `${SERVER}/handbook-extremists/v1/person`,
  auth: `${SERVER}/crm/v1/keycloak`,
  resetLk: `${SERVER}/crm/v1/keycloak/reset`,
  [ENTITY_TYPE.STRATEGIES]: `${SERVER}/crm/v1/communications/strategies`,
  [ENTITY_TYPE.TEMPLATES]: `${SERVER}/crm/v1/communications/templates`,
  [ENTITY_TYPE.SIP]: `${SERVER}/crm/v1/sip-phone`,
  [ENTITY_TYPE.CHAT]: `${SERVER}/chat-api/v1/chat/messages`,
  [ENTITY_TYPE.CLAIMS_TOPIC]: `${SERVER}/crm/v1/claims/claim-topics`,
  [ENTITY_TYPE.CLAIMS]: `${SERVER}/crm/v1/claims`
}

const ENTITY_FIELD_ID = {
  [ENTITY_TYPE.PRODUCT]: '', // 'products_settings_id',
  [ENTITY_TYPE.EXTENSIONS]: '', // 'extension_settings_id',
  [ENTITY_TYPE.CLIENTS]: '', // 'client_id',
  [ENTITY_TYPE.PROFILES]: '', // 'profile_id',
  [ENTITY_TYPE.APPLICATIONS]: '', // 'application_id',
  [ENTITY_TYPE.PAYMENTS]: '', // 'funds_transactions_id',
  [ENTITY_TYPE.LOANS]: '', // 'loan_id',
  [ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS]: '', // 'external_service_id',
  [ENTITY_TYPE.DIALOGUES]: '',
  [ENTITY_TYPE.PROMOCODES]: '',
  [ENTITY_TYPE.SEGMENTS]: '',
  [ENTITY_TYPE.QUEUES]: 'queue_params_id',
  [ENTITY_TYPE.COLLECTORS]: '',
  [ENTITY_TYPE.DEBTS]: '',
  [ENTITY_TYPE.DEBTS_QUEUE]: ''
}

const REDIRECT_ERROR = 'opaqueredirect'

function getSessionToken() {
  /*const token = JSON.parse(sessionStorage.getItem("authUser")) ?
    JSON.parse(sessionStorage.getItem("authUser")).token :
    null;
  if (token)
    return {'Authorization': `Bearer ${token}`};*/
  return {}
}

/**
 * Why not use new URLSearchParams?
 * new URLSearchParams(null) -> null=
 * new URLSearchParams(0=) -> 0=
 * new URLSearchParams('a') -> a=
 * @param {*} query
 * @param {boolean} shouldPrependWithQuestion
 * @returns {string}
 */
export const encodeURIComponents = (
  query,
  shouldPrependWithQuestion = false
) => {
  if (typeof query !== 'object' || query == null || Array.isArray(query))
    return ''

  let result = Object.keys(query)
    .reduce((result, key) => {
      if (
        typeof query[key] === 'function' ||
        typeof query[key] === 'object' ||
        query[key] === undefined ||
        Array.isArray(query[key]) ||
        query[key] === null ||
        query[key] === ''
      )
        return result
      return [
        ...result,
        `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
      ]
    }, [])
    .join('&')

  if (shouldPrependWithQuestion) {
    result = result && result.length > 0 ? '?' + result : result
  }
  return result
}

async function apiGet(endpoint, query = {}, headers = {}) {
  const queryParamsString = encodeURIComponents(query)
  return apiQuery(
    `${endpoint}${queryParamsString ? '?' : ''}${queryParamsString}`,
    null,
    'GET',
    headers
  )
}
async function apiPost(endpoint, query = {}, headers = {}) {
  return apiQuery(endpoint, query, 'POST', headers)
}

async function apiDelete(endpoint, query = {}, headers = {}) {
  return apiQuery(endpoint, null, 'DELETE', headers)
}

async function apiPut(endpoint, query = {}, headers = {}) {
  /*const queryParamsString = Object.keys(query).reduce((result, key) => {
    return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`]
  }, []).join('&');*/
  return apiQuery(endpoint, query, 'PUT', headers)
}

async function apiQuery(endpoint, query, method, headers = {}) {
  let init = {}

  if (
    headers['Content-Type'] !== undefined &&
    headers['Content-Type'].includes('form-data')
  ) {
    init = {
      headers: {
        Accept: 'application/json',
        ...getSessionToken()
      },
      redirect: 'manual',
      credentials: 'include',
      method,
      body: query
    }
  } else {
    init = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getSessionToken(),
        ...headers
      },
      redirect: 'manual',
      credentials: 'include',
      method,
      body: query ? JSON.stringify(query) : null
    }
  }

  return fetch(endpoint, init)
    .then(async (response) => {
      if (response.status === 0 && response.type === REDIRECT_ERROR) {
        throw { responseStatus: 0, errorMessage: REDIRECT_ERROR }
      }

      if (response.status === 422) {
        const json = await response.json()
        const errorMessage = json.detail.reduce(
          (str, det) =>
            `${str} ${det.loc && det.loc.length ? det.loc[1] : ''}: ${
              det.msg
            }; `,
          ''
        )
        throw { responseStatus: response.status, errorMessage }
      }

      if (response.status === 404) {
        const json = await response.json()
        const errorMessage = json.detail
        throw { responseStatus: response.status, errorMessage }
      }

      if (response.status === 409) {
        const json = await response.json()
        const errorMessage = json.detail
        throw { responseStatus: response.status, errorMessage }
      }

      if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 403
      ) {
        throw { responseStatus: response.status, errorMessage: 'Access error' }
      }

      if (Math.trunc(response.status / 100) !== 2) {
        //all 20x are good statuses
        throw {
          responseStatus: response.status,
          errorMessage: `Bad response status: ${response.status}`
        }
      }

      if (response.headers.get('content-type') === 'application/json')
        return response.json()
      return response
    })
    .then((data) => {
      return data
    })
    .catch((error) => {
      const redirectError = error.errorMessage === REDIRECT_ERROR

      if (redirectError) {
        if (!window.origin.includes('localhost'))
          window.open(`${SERVER}/crm/v1/keycloak` + '/redirect', '_self')
      }
      throw error.errorMessage || error
    })
}

const API = {
  ENTITY_TYPE,
  END_POINTS,
  LIST_LIMIT,

  debounceTimers: {},

  deleteClient: (id) => apiDelete(END_POINTS['clients'] + `/${id}`),

  updateClient: (client) => {
    return apiPost(`${SERVER}/crm/v1/clients` + '/forms-data', client, {
      'Content-Type': 'multipart/form-data'
    })
  },

  getSegments: () => {
    return apiGet(`${SERVER}/crm/v1/calling/stages`)
  },

  getAllCollectors: () => {
    return apiGet(`${SERVER}/crm/v1/calling/collectors`)
  },

  getStageCollectors: (id) => {
    return apiGet(`${SERVER}/crm/v1/calling/stages` + `/${id}/collectors`)
  },

  getUserStagesCollector: (id) => {
    return apiGet(`${SERVER}/crm/v1/calling/collectors` + `/${id}`)
  },

  getGroupCollectors: (id) => {
    return apiGet(`${SERVER}/crm/v1/calling/groups` + `/${id}`)
  },

  createGroupCollectors: (body) => {
    return apiPost(`${SERVER}/crm/v1/calling/groups`, body)
  },

  deleteGroupCollectors: (id) => {
    return apiDelete(`${SERVER}/crm/v1/calling/groups` + `/${id}`)
  },

  getAllGroups: () => {
    return apiGet(`${SERVER}/crm/v1/calling/groups`)
  },

  /**
   * @deprecated Use redux toolkit query
   * @param {string} id
   * @param {Object} body
   * @param {{is_auto: string, promotion_id: string}} [query]
   */
  updateStage: (id, body, query) => {
    return apiPost(
      `${SERVER}/crm/v1/calling/stages` +
        `/${id}?${encodeURIComponents(query)}`,
      body
    )
  },

  /**
   * @deprecated Use redux toolkit query
   * @param {Object} body
   * @param {{is_auto: string, promotion_id: string}} [query]
   */
  createStage: (body, query) => {
    return apiPost(
      `${SERVER}/crm/v1/calling/stages` + `?${encodeURIComponents(query)}`,
      body
    )
  },

  postSegmentToCollector: (id, body) => {
    return apiPost(`${SERVER}/crm/v1/calling/collectors` + `/${id}`, body)
  },

  updateClientWork: (workData) => {
    return apiPost(`${SERVER}/crm/v1/clients` + '/work-data', workData)
  },

  updateClientAddresses: (addressData) => {
    return apiPost(`${SERVER}/crm/v1/clients` + '/address-data', addressData)
  },

  updateClientDocuments: (documentsData) => {
    return apiPost(
      `${SERVER}/crm/v1/clients` + '/documents-data',
      documentsData
    )
  },

  resetLk: (body) => apiPost(`${SERVER}/crm/v1/keycloak/reset`, body),
  changePassword: (body) => apiPost(END_POINTS['changePassword'], body),

  getLoanData: (data_type, loan_id) => {
    return apiGet(`${SERVER}/crm/v1/loans` + `/${loan_id}/${data_type}`)
  },

  getLoansBki: (loan_id) => {
    return apiGet(
      `${SERVER}/crm/v1/loans-bki-events` + `/${loan_id}` + `/loans_bki_events`
    )
  },

  getLoanHolidays: (loan_id) => {
    return apiGet(`${SERVER}/crm/v1/loans-holidays` + `/${loan_id}`)
  },

  getLoanAgencies: (loan_id) => {
    return apiGet(`${SERVER}/crm/v1/collections-external` + `/${loan_id}`)
  },

  addLoanHolidays: (
    loan_id,
    begin_date,
    end_date,
    holiday_type_id,
    application_date
  ) => {
    return apiPost(`${SERVER}/crm/v1/loans-holidays` + `/${loan_id}/add`, {
      begin_date,
      end_date,
      holiday_type_id,
      application_date
    })
  },

  editLoanHolidays: (
    loan_id,
    begin_date,
    end_date,
    holiday_type_id,
    application_date
  ) => {
    return apiPut(`${SERVER}/crm/v1/loans-holidays` + `/${loan_id}`, {
      begin_date,
      end_date,
      holiday_type_id,
      application_date
    })
  },

  createProlongation: (loan_id) => {
    return apiPost(`${SERVER}/crm/v1/loans` + `/${loan_id}/add-prolongation`)
  },

  getLoanCredit: (loan_id) => {
    return apiGet(`${SERVER}/crm/v1/loans` + `/${loan_id}/credit-details`)
  },

  getLoanCard: (loan_id) => {
    return apiGet(`${SERVER}/crm/v1/loans` + `/${loan_id}/card`)
  },

  getApplicationLoan: (application_id) => {
    return apiGet(`${SERVER}/crm/v1/applications` + `/${application_id}/loan`)
  },

  getApplicationVerification: (application_id) => {
    return apiGet(
      `${SERVER}/crm/v1/applications` + `/${application_id}/verification`
    )
  },

  verifyApplicationDocumentsStart: (application_id) => {
    return apiPost(
      `${SERVER}/crm/v1/applications` + `/manual-verfication-start`,
      {
        application_id
      }
    )
  },

  verifyApplicationDocumentsEnd: (verfication) => {
    return apiPost(
      `${SERVER}/crm/v1/applications` + `/manual-verfication-end`,
      verfication
    )
  },

  getDialogue: (clientId) => {
    return apiGet(`${SERVER}/crm/v1/clients` + `/${clientId}/feedbacks`)
  },

  updateDialogue: (data) => {
    return apiPost(`${SERVER}/crm/v1/clients` + `/${data.id}/feedbacks`, data)
  },

  getApplicationDocuments: (applicationId) => {
    return apiGet(
      `${SERVER}/crm/v1/documents` + `/form-cards/application/${applicationId}`
    )
  },

  getClientDocuments: (clientId) => {
    return apiGet(
      `${SERVER}/crm/v1/documents` + `/form-cards/client/${clientId}`
    )
  },

  getAllPermissions: () => {
    return apiGet(`${SERVER}/crm/v1/keycloak/existing-permissions`)
  },

  getUserPermissions: (id) => {
    return apiGet(`${SERVER}/crm/v1/keycloak/users` + `/${id}/permissions`)
  },

  getRolePermission: (name) => {
    return apiQuery(
      `${SERVER}/crm/v1/keycloak/groups` + `/${name}/permissions`,
      null,
      'GET'
    )
  },

  createRole: (name) => {
    return apiQuery(`${SERVER}/crm/v1/keycloak/groups`, { name }, 'POST')
  },

  deleteRole: (name) => {
    return apiQuery(
      `${SERVER}/crm/v1/keycloak/groups` + `/${name}`,
      null,
      'DELETE'
    )
  },

  addRolePermission: (name, permissions) => {
    return apiQuery(
      `${SERVER}/crm/v1/keycloak/groups` + `/${name}/add-permissions`,
      { permissions },
      'POST'
    )
  },

  getClientAddons: (clientId, type) => {
    return apiGet(`${SERVER}/crm/v1/clients` + `/${clientId}/${type}`)
  },

  getClientSignedDocs: (clientId) => {
    return apiGet(`${SERVER}/crm/v1/clients` + `/${clientId}/signed-documents`)
  },

  getPersons: () => {
    return apiGet(`${SERVER}/crm/v1/calling/person`)
  },

  getCommunications: (profileId) => {
    return apiGet(`${SERVER}/crm/v1/calling` + `/${profileId}`)
  },

  getLoanSignedDocs: (loanId) => {
    return apiGet(`${SERVER}/crm/v1/loans` + `/${loanId}/signed-documents`)
  },

  postLoanProlongation: (loanId, body) => {
    return apiPost(`${SERVER}/crm/v1/loans` + `/${loanId}/prolongation`, body)
  },

  postLoanWriteoff: (loanId, body) => {
    return apiPost(`${SERVER}/crm/v1/loans` + `/${loanId}/writeoff`, body)
  },

  applicationAsFraudulent: (application_id) => {
    return apiPost(`${SERVER}/crm/v1/applications` + `/${application_id}/fraud`)
  },

  getApplicationSignedDocs: (appId) => {
    return apiGet(
      `${SERVER}/crm/v1/applications` + `/${appId}/signed-documents`
    )
  },

  getSignedDocuments: (id) => {
    return apiGet(`${SERVER}/crm/v1/documents` + `/signed/${id}`)
  },

  logout: () => {
    return `${SERVER}/crm/v1/logout`
  },

  //@TODO: rewrite to RTK
  getList: (
    entityType = null,
    filter = '',
    offset = 0,
    limit = window.location.pathname === '/debts'
      ? LIST_LIMIT_DEBTS
      : LIST_LIMIT
  ) => {
    if (!entityType) throw 'called without entityType'
    const endpoint = END_POINTS_LIST[entityType] || END_POINTS[entityType]
    if (!endpoint) throw `entityType ${entityType} not found`

    let strFilter = ''
    if (typeof filter === 'string') strFilter = `keyword=${filter}&`
    if (typeof filter === 'object')
      strFilter = Object.keys(filter).reduce((str, field) => {
        const filterValue = filter[field]
        if (filterValue === undefined || filterValue === null) return str
        if (filterValue.toString().trim().length === 0) return str
        str += `${field}=${filter[field]}&`
        return str
      }, '')

    return apiGet(
      endpoint +
        `?${strFilter}offset=${offset}&limit=${
          limit || window.location.pathname === '/debts'
            ? LIST_LIMIT_DEBTS
            : LIST_LIMIT
        }`
    )
  },

  //@TODO: rewrite to RTK
  getEntity: (entityType = null, id) => {
    if (!entityType) throw 'called without entityType'
    const endpoint = END_POINTS_LIST[entityType] || END_POINTS[entityType]
    if (!endpoint) throw `entityType ${entityType} not found`

    const filterIdName = ENTITY_FIELD_ID[entityType]
    if (filterIdName) {
      return apiGet(endpoint + `?${filterIdName}=${id}`)
    }
    return apiGet(endpoint + `/${id}`)
  },

  //@TODO: rewrite to RTK
  updateEntity: (entityType, entity) => {
    if (!entityType) throw 'called without entityType'
    const endpoint = END_POINTS_UPDATE[entityType] || END_POINTS[entityType]
    if (!endpoint) throw `entityType ${entityType} not found`
    const { id, ...fields } = entity
    return apiPut(endpoint + `/${id}`, fields)
  },

  //@TODO: rewrite to RTK
  createEntity: (entityType, entity) => {
    if (!entityType) throw 'called without entityType'
    const endpoint = END_POINTS_CREATE[entityType] || END_POINTS[entityType]
    if (!endpoint) throw `entityType ${entityType} not found`
    const { id, ...fields } = entity
    return apiPost(endpoint, entity)
  },

  //@TODO: rewrite to RTK
  deleteEntity: (entityType, id) => {
    if (!entityType) throw 'called without entityType'
    const endpoint = END_POINTS[entityType]
    if (!endpoint) throw `entityType ${entityType} not found`
    return apiDelete(endpoint + `/${id}`)
  },

  deleteQueue: (id) => apiPost(`${SERVER}/crm/v1/calling/queue-params/${id}`),

  login: ({ code }) =>
    apiPost(`${SERVER}/crm/v1/keycloak` + `/get_token_by_code?code=${code}`),

  decisionStatuses: () =>
    apiPost(`${SERVER}/crm/v1/roles/verifications/decision-statuses`),

  decide: (applicationId, status, description, save_type) =>
    apiPost(
      `${SERVER}/crm/v1/roles/verifications/applications/${applicationId}/decide`,
      {
        status,
        description,
        save_type
      }
    ),

  decideInfo: (applicationId) =>
    apiGet(
      `${SERVER}/crm/v1/roles/verifications/applications/${applicationId}/decide`
    ),

  reloadContract: (loanId, drop_pa, drop_insurance) =>
    apiPost(`${SERVER}/crm/v1/payments/${loanId}/reload-contract`, {
      drop_pa,
      drop_insurance
    }),

  blockClient: (client_id, reason_id, comment) =>
    apiPost(`${SERVER}/crm/v1/applications/block-client`, {
      client_id,
      reason_id,
      comment
    }),

  unblockClient: (client_id, comment) =>
    apiPost(`${SERVER}/crm/v1/applications/unblock-client`, {
      client_id,
      comment
    }),

  reassign: (user_from, user_to, collection_stage_id) =>
    apiPost(`${SERVER}/crm/v1/supervisor/assigment`, {
      user_from,
      user_to,
      collection_stage_id
    }),

  assign: (loan_id, user_id, collection_stage_id) =>
    apiPost(`${SERVER}/crm/v1/supervisor/assigment-in-loan`, {
      loan_id,
      user_id,
      collection_stage_id
    }),

  uploadAgencies: (file, email) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('email', email)
    return fetch(`${SERVER}/crm/v1/collections-external` + '/upload-changes/', {
      redirect: 'manual',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
  },

  getClientsTags: () => apiGet(`${SERVER}/crm/v1/tags/clients`),

  addCustomTags: (tag, type) =>
    apiPost(`${SERVER}/crm/v1/tags/add_type?tag=${tag}&type=${type}`),

  addMassTags: (file, type) => {
    const formData = new FormData()
    formData.append('csv_file', file)
    return fetch(`${SERVER}/crm/v1/tags` + `/mass_tag_addition?type=${type}`, {
      redirect: 'manual',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
  },

  startStrategy: (id) =>
    apiPost(`${SERVER}/crm/v1/communications/strategies/${id}/start`),

  stopStrategy: (id) =>
    apiPost(`${SERVER}/crm/v1/communications/strategies/${id}/stop`),

  deleteActionStrategy: (strategy_id, action_id, type) => {
    return apiDelete(
      `${SERVER}/crm/v1/communications/strategies` +
        `/${strategy_id}/actions/${action_id}?action_type=${type}`
    )
  },

  addActionStrategy: (id, type, body) => {
    return apiPost(
      `${SERVER}/crm/v1/communications/strategies` +
        `/${id}/actions?action_type=${type}`,
      body
    )
  },

  getTemplates: (type) =>
    apiGet(`${SERVER}/crm/v1/communications/templates/${type}`),

  getTemplate: (type, id) =>
    apiGet(`${SERVER}/crm/v1/communications/templates/${type}/${id}`),

  updateTemplate: (type, id, body) =>
    apiPut(`${SERVER}/crm/v1/communications/templates/${type}/${id}`, body),

  createTemplate: (type, body) =>
    apiPost(`${SERVER}/crm/v1/communications/templates/${type}`, body),

  sendMessageByLoanId: (loan_id, body) => {
    return apiPost(`${SERVER}/crm/v1/loans/${loan_id}/send-new`, {
      ...body
    })
  },

  getSmsTemplates: (loan_id) => {
    return apiGet(`${SERVER}/crm/v1/loans/${loan_id}/get-sms-templates`)
  },

  getRightSendMessage: (loan_id) => {
    return apiGet(`${SERVER}/crm/v1/loans/${loan_id}/is-ok-to-communicate`)
  },

  getAllKASMSTemplates: () => {
    return apiGet(`${SERVER}/crm/v1/communications/templates/sms`)
  },

  linkKA: (agencie_id, body) => {
    return apiPut(
      `${SERVER}/crm/v1/collections-external/agencies/${agencie_id}/link-ka`,
      body
    )
  },

  recPayment: (loan_id, body) => {
    return apiPost(
      `${SERVER}/crm/v1/payments/best2pay/rec-payment/${loan_id}`,
      body
    )
  },

  recPaymentDpD: (body) => {
    return apiPost(`${SERVER}/crm/v1/payments/best2pay/rec-payment`, body)
  },

  getAnketaByClientId: (client_id) => {
    return fetch(
      `${SERVER}/crm/v1/clients/${client_id}/get-rendered-client-template`,
      {
        redirect: 'manual',
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'text/html'
        }
      }
    )
  },

  getNbkiReport: (request_id) => {
    return fetch(
      `${SERVER}/crm/v1/roles/verifications/${request_id}/nbki_report`,
      {
        redirect: 'manual',
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/xml'
        }
      }
    )
  },

  getChatHistory: (profile_id) => {
    return apiGet(
      `${SERVER}/chat-api/v1/chat/messages?profile_id=${profile_id}`
    )
  },

  answerToChat: (profile_id, content) => {
    return apiPost(
      `${SERVER}/chat-api/v1/chat/messages?profile_id=${profile_id}`,
      {
        content
      }
    )
  }
}

export default API
