import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import {
  useGetPaymentTypesQuery,
  useGetTransactionByIdQuery
} from 'src/services/payments'
import { paymentsLink } from './constants'
import { InputField } from './types'
import { crateLinkButtonView } from 'src/Components/Common/CreateLinkButtonView'

export const PaymentView: FC = () => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const id = searchParams.get('id') as string
  const edit = searchParams.get('edit') as string

  const { data: paymentTypes } = useGetPaymentTypesQuery()

  const {
    data: payment,
    error: listError,
    isLoading: listLoading
  } = useGetTransactionByIdQuery(id, {
    skip: !id
  })
  const inputInitials: Record<string, InputField> = {
    loan_id: { label: 'id', initial: '', placeholder: '-' },
    direction: { label: 'direction', initial: '', placeholder: '-' },
    amount: { isAmount: true, label: 'amount', initial: '', placeholder: '-' },
    comment: {
      label: 'comment',
      initial: '',
      placeholder: '-'
    },
    payment_type: {
      label: 'payment_type',
      initial: '',
      placeholder: '-',
      mappingValue: (value) =>
        paymentTypes?.find((it) => it.id === value)?.name ?? '-'
    },
    is_accepted: { label: 'is_accepted', initial: '-', placeholder: '-' },
    prepayment: {
      label: 'prepayment',
      fieldName: 'is_prepayment',
      initial: '-',
      placeholder: '-',
      mappingValue: (value: boolean) => (value ? '1' : '-')
    },
    receipt_date: {
      isDateTime: true,
      label: 'Дата поступления',
      initial: '',
      placeholder: '-'
    },
    accept_date: {
      isDateTime: true,
      label: 'accept_date',
      initial: '',
      placeholder: '-'
    },
    last_name: { label: 'last_name', initial: '', placeholder: '-' },
    first_name: { label: 'first_name', initial: '', placeholder: '-' },
    middle_name: { label: 'middle_name', initial: '', placeholder: '-' }
  }

  useEffect(() => {
    //страница редактирования пока не реализована
    setIsEdit(edit === 'true' && false)
  }, [edit])

  const pageTitle = t(isEdit ? 'Edit payment' : 'View payment')
  document.title = t(pageTitle)

  if (!id || listError) {
    return (
      <ErrorPage
        backLink={paymentsLink}
        title={pageTitle}
        error={listError ? listError : 'No payment id found'}
      />
    )
  }

  if (listLoading) {
    return <LoadingPage backLink={paymentsLink} title={pageTitle} />
  }
  if (!payment) {
    return null
  }
  const profileLink = crateLinkButtonView(
    `/profile-view?id=${payment.profile_id?.toString()}`,
    <i className='ri-account-circle-line fs-16'></i>
  )
  const loanLink = crateLinkButtonView(
    `/loan-view?id=${payment.loan_id?.toString()}`,
    <i className='ri-pie-chart-line fs-16'></i>
  )
  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={pageTitle} />
        <Card>
          <CardBody>
            <MetaBar
              backLink={paymentsLink}
              editLink={
                isEdit ? `/payment-view?id=${payment.id}&edit=true` : null
              }
              entity={payment}
              buttons={[profileLink, loanLink]}
            />
            <Row className='mt-3'>
              {Object.keys(inputInitials).map((fieldKey, _i) => {
                const htmlForId = `validation-${fieldKey}`
                return (
                  <Col className='col-auto' key={`${fieldKey}`}>
                    <Label htmlFor={htmlForId}>
                      {t(inputInitials[fieldKey].label)}
                    </Label>
                    <Input
                      name={`${fieldKey}`}
                      type='text'
                      className='form-control form-control-sm'
                      id={htmlForId}
                      value={model.fieldValueToText(
                        fieldKey,
                        payment,
                        inputInitials
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
